import React from 'react';

export const Star = ({ className, title }) => (
  <svg
    className={className}
    width="112"
    height="123"
    viewBox="0 0 112 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <g>
      <path
        d="M57.8202 3.7002L68.0802 52.9602L111.95 63.2202L68.0802 73.4702L57.8202 122.74L47.5702 73.4702L3.7002 63.2202L47.5702 52.9602L57.8202 3.7002Z"
        fill="black"
      />
      <path
        d="M54.59 0.459961L64.84 49.73L108.72 59.98L64.84 70.24L54.59 119.5L44.33 70.24L0.459961 59.98L44.33 49.73L54.59 0.459961Z"
        fill="white"
        stroke="black"
        strokeWidth="0.93"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="111.95" height="122.74" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
