import * as React from "react"

export const Truck = ({ className, title }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="200 90 320 320"
  >
    <title>{title}</title>
    <defs>
      <style>
        {
          ".d{fill:#676fec}.d,.e,.f{stroke:#222;stroke-miterlimit:10;stroke-width:1.14px}.e{fill:#222}.f{fill:#3a3a3a}.g{fill:#f9f7f7}"
        }
      </style>
    </defs>
    <path
      className="e"
      d="M379.36 189.68l-.87 160.25 64.86.35c11.92.06 21.63-9.54 21.69-21.46l.34-62.38c.02-4.36-1.28-8.63-3.73-12.24l-37.24-54.83a21.567 21.567 0 00-17.66-9.45l-27.4-.24z"
    />
    <rect
      className="e"
      x={45.28}
      y={100.63}
      width={340.26}
      height={248.42}
      rx={24.48}
      ry={24.48}
      transform="rotate(.31 215.007 224.906)"
    />
    <circle className="e" cx={388.07} cy={365.54} r={41.45} />
    <circle className="e" cx={123.17} cy={364.11} r={41.45} />
    <path
      className="d"
      d="M369.18 182.81l-.87 160.25 64.86.35c11.92.06 21.63-9.54 21.69-21.46l.34-62.38c.02-4.36-1.28-8.63-3.73-12.24l-37.24-54.83a21.567 21.567 0 00-17.66-9.45l-27.4-.24z"
    />
    <rect
      className="d"
      x={35.1}
      y={93.76}
      width={340.26}
      height={248.42}
      rx={24.48}
      ry={24.48}
      transform="rotate(.31 203.897 217.543)"
    />
    <circle className="f" cx={377.89} cy={358.67} r={41.45} />
    <circle className="f" cx={112.99} cy={357.24} r={41.45} />
    <g>
      <path
        id="b"
        className="g"
        d="M284.74 216.21c-21.64 15.77-52.92 24.08-79.82 23.93-37.71-.2-71.59-14.34-97.15-37.67-2.01-1.84-.19-4.31 2.23-2.88 27.63 16.28 61.85 26.17 97.26 26.36 23.88.13 50.17-4.67 74.38-14.79 3.66-1.53 6.69 2.43 3.1 5.05"
      />
      <path
        id="c"
        className="g"
        d="M293.76 206.01c-2.73-3.53-18.2-1.76-25.15-.98-2.12.25-2.43-1.6-.52-2.91 12.36-8.6 32.56-5.99 34.91-3.07 2.34 2.93-.74 23.18-12.37 32.79-1.78 1.48-3.47.68-2.67-1.29 2.63-6.48 8.54-20.99 5.8-24.54"
      />
    </g>
  </svg>
);
