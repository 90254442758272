import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { title, text } from './newsletter-success-message.module.scss';
import { useTranslation } from '../../../../i18n';

function NewsletterSuccessMessage({ styles = {}, children }) {
  const msgElement = useRef(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (msgElement.current) {
      msgElement.current.focus();
    }
  }, []);

  return (
    <div role="alert" ref={msgElement} tabIndex="-1">
      <p className={(title, styles.title)}>{t('newsletter_success_title')}</p>
      <p className={(text, styles.text)}>{children}</p>
    </div>
  );
}

NewsletterSuccessMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default NewsletterSuccessMessage;
