import React, { useCallback, useContext, useState, useRef } from 'react';
import {
  NewsletterForm,
  NewsletterSignupContainer,
  NewsletterSuccessMessage,
} from '../../components';
import styles from './newsletter-signup-shopping.module.scss';
import successStyles from './newsletter-signup-success.module.scss';
import { Star } from './svg/Star';
import { ThankYou } from './svg/ThankYou';
import { Truck } from './svg/Truck';
import { SubscribeModal } from '@buzzfeed/react-components';
import {
  trackClientContentAction,
  trackClientImpression,
  trackClientInternalLink
} from '../../../../hooks/analytics/client-event-tracking';
import BuzzContext from '../../../../contexts/buzz';
import { getUserCookieInfo } from '../../../../utils/userInfo';

function NewsletterSignupShopping({
  trackSignupSuccess,
  title = 'Get Our Newsletter',
  description = 'Score buzz-worthy products, shopping advice, and deals delivered to your inbox!',
  promotion = false
}) {
  const buzz = useContext(BuzzContext);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const usersEmail = useRef();
  const onSubmitSuccess = useCallback((email) => {
    setSubmitSuccess(true);
    usersEmail.current = email;
    setIsSubscribeModalOpen(true);
    trackSignupSuccess('shopping');
  }, [trackSignupSuccess]);

  return (
    <>
      <NewsletterSignupContainer className={styles.container}>
        <div className={styles.contentWrapper}>
          {submitSuccess ? (
            <div className={styles.success}>
              <Star className={`${styles.star} ${styles.star1}`} title="star" />
              <Star className={`${styles.star} ${styles.star2}`} title="star" />
              <Star className={`${styles.star} ${styles.star3}`} title="star" />
              <Star className={`${styles.star} ${styles.star4}`} title="star" />
              <ThankYou className={styles.thankYouImg} title={'thank-you'} />
              <NewsletterSuccessMessage styles={successStyles}>
                Check your inbox to confirm your subscription.
              </NewsletterSuccessMessage>
            </div>
          ) : (
            <>
              {promotion === 'newsletter-primeday' &&
                <div className={styles.truckWrapper}>
                  <Truck id="primeTruck" className={styles.truckImg} title="truck" />
                </div>
              }
              <div className={styles.titleWrapper}>
              {promotion !== 'newsletter-primeday' ? <div className={styles.holidayIcon} /> : '' }
                <div className={styles.textWrapper}>
                  <h2>
                    <span className={styles.title}>{title}</span>
                  </h2>
                  <div className={styles.description}>{description}</div>
                </div>
              </div>
              <NewsletterForm
                category="shopping"
                newsletterId="buzzfeed_email_shopping"
                className={styles.form}
                onSubmitSuccess={onSubmitSuccess}
              />
            </>
          )}
        </div>
      </NewsletterSignupContainer>
      {(isSubscribeModalOpen && !getUserCookieInfo()) && <SubscribeModal
        email={usersEmail.current}
        topic="Shopping"
        onClose={() => setIsSubscribeModalOpen(false)}
        track={{
          commonTrackingData: {
            item_type: 'submission',
            item_name: 'email',
            unit_name: buzz.id,
            unit_type: 'buzz_bottom',
          },
          internalLink: (data) => trackClientInternalLink(buzz, data),
          contentAction: (data) => trackClientContentAction(buzz, data),
          impression: (data) => trackClientImpression(buzz, data),
        }}
      />}
    </>
  );
}

export default NewsletterSignupShopping;
