import fetch from 'isomorphic-unfetch';

/**
 * Performs a POST request to the specified endpoint
 * Converts body into JSON string
 */
export async function postJson(url, body, { hideResponseBody = false, headers = {} }) {
  try {
    const response = await fetch(`${url}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers
    });

    if (!response.ok) {
      return {
        error: response,
      };
    }

    return {
      response: hideResponseBody ? {ok: 'ok'} : await response.json(),
    };
  } catch (error) {
    return { error };
  }
}
