import React from 'react';
import PropTypes from 'prop-types';
import { useContext, useReducer, useEffect, useRef } from 'react';
import styles from './newsletter-form.module.scss';
import { isValidEmail } from '../../../../utils/isValidEmail';
import ReCAPTCHA from 'react-google-recaptcha';
import BuzzContext from '../../../../contexts/buzz';
import { site_captcha_key } from '../../../../constants';
import { trackFacebookNewsletterSignup } from '../../../../hooks/analytics/facebook/client';
import {
  submitSucceeded,
  submitFailed,
  captchaValidated,
  submitStart,
  emailInputFocused,
} from './actions';
import { useTranslation } from '../../../../i18n';
import { postJson } from '../../../../utils/postJson';
import storage from '@buzzfeed/bf-utils/lib/storage';
import { CheckmarkIcon, useBreakpoint } from '@buzzfeed/react-components';
import { addHemCookie } from '@buzzfeed/react-components/lib/utils/addHemCookie';
import DestinationContext from '../../../../contexts/destination';
import ReCAPTCHABranding from '../ReCAPTCHABranding';

// Track instances of this form to generate unique id
let formCount = 0;

function validateControls(formControls) {
  const error = (control, message) => ({ error: { control, message } });

  const emailEl = formControls.email;
  const email = emailEl.value;
  if (!isValidEmail(email)) {
    return error(emailEl, 'newsletter_email_error');
  }

  return { email };
}

function NewsletterFormSpinner() {
  return (
    <div className={styles.spinner}>
      <span>Loading</span>
    </div>
  );
}

function NewsletterFormError({ for: forElement, children }) {
  const id = forElement
    ? `${forElement.getAttribute('id')}-error`
    : 'newsletter-error';
  return (
    <p id={id} className={styles.error} role="alert">
      {children}
    </p>
  );
}

NewsletterFormError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  for: PropTypes.object,
};

function NewsletterFormSuccess({ for: forElement, children }) {
  const id = forElement
    ? `${forElement.getAttribute('id')}-success`
    : 'newsletter-success';
  return (
    <p id={id} className={styles.success} role="alert">
      {children}
    </p>
  );
}

NewsletterFormSuccess.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  for: PropTypes.object,
};

function getInputId({ formElement }) {
  const inputIdBase = 'newsletter-email-signup';
  const existingInput = formElement.current
    ? formElement.current.querySelector(`input[id^="${inputIdBase}"]`)
    : null;
  if (existingInput) {
    return existingInput.id;
  }
  formCount++;
  return `${inputIdBase}-${formCount}`;
}

function NewsletterForm({
  category,
  newsletterId,
  className,
  buttonClassName = '',
  submitBoxClassName = '',
  promotion = '',
  onSubmitSuccess,
  onSubmitError,
}) {
  const [state, dispatch] = useReducer(
    (prevState, action) => action(prevState),
    {}
  );
  const formElement = useRef(null);
  const { t } = useTranslation('common');
  const recaptchaRef = useRef();
  const { base_url, destination } = useContext(DestinationContext);
  const buzz = useContext(BuzzContext);
  const isMobile = useBreakpoint('(max-width:500px)'); // page level break is based on bf_header_ui break
  let newsletterPlaceholder = t('newsletter_placeholder');
  let newsletterSuccessMessage = t('newsletter_success_message');
  let serverError = t('server_error');

  // Effect for submitting form data to the API
  useEffect(() => {
    if (!state.submitting) {
      return;
    }

    (async function submitForm() {
      const { token, email } = state.formData;
      const data = {
        brand: 'buzzfeed',
        subscriptions: [newsletterId],
        source: isMobile ? 'buzzfeed-mobileweb-hub' : 'buzzfeed_desktop_hub',
        'g-recaptcha-response': token,
        email,
      };
      const { response, error } = await postJson(
        `${base_url}/newsletters/api/subhub/v1/users`,
        data,
        {
          hideResponseBody: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      if (error || !response.ok) {
        console.error({ error });
        return dispatch(
          submitFailed({
            error: {
              message: serverError,
            },
          })
        );
      }
      return dispatch(submitSucceeded());
    })();
  }, [base_url, isMobile, newsletterId, state.formData, state.submitting, t]);

  // Effect for triggering a success handler when submission is successful
  useEffect(() => {
    if (state.success) {
      onSubmitSuccess(state.formData.email);
      addHemCookie(state.formData.email);
      storage.sessionSet({ key: 'newsletterAddressable', value: 'true' });

      trackFacebookNewsletterSignup(destination, buzz, { category });
    } else if (state.error && typeof onSubmitError === 'function') {
      onSubmitError();
    }
  }, [
    onSubmitSuccess,
    onSubmitError,
    state.success,
    state.error,
    state.formData,
    destination,
    category
  ]);

  // Effect for fetching captcha token when form is focused
  useEffect(() => {
    if (!state.focused) {
      return;
    }

    (async function dispatchCaptchaToken() {
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset(); // reset allows for subsequent submissions without error.
      dispatch(captchaValidated(token));
    })();
  }, [state.focused]);

  const handleFormSubmit = ev => {
    ev.preventDefault();
    if (state.submitting) {
      return;
    }

    // reset success state with every new form submission
    state.success = false;

    const { email, error } = validateControls(
      formElement.current.elements
    );

    // for failed form validation dispatch an error and focus the control which had an error
    if (error) {
      const { control, message } = error;
      dispatch(
        submitFailed({
          error: {
            message: t(message),
            formControl: control,
          },
        })
      );
      control.focus();
      return;
    }

    // reset focused state so that a new captcha is generated for the next form submission
    state.focused = false;

    dispatch(
      submitStart({
        token: state.token,
        email,
      })
    );
    return;
  };

  const handleInputFocus = () => {
    dispatch(emailInputFocused());
  };

  const inputId = getInputId({ formElement });
  return (
    <form className={`${className || ''} ${styles.form}`} ref={formElement}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={site_captcha_key}
        style={{ visibility: 'hidden' }}
      />
      {state.submitting && <NewsletterFormSpinner />}
      <fieldset
        className={`${styles.fieldset} ${
          state.submitting ? styles.loading : ''
        }`}
      >
        <legend className={styles.legend}>Newsletter signup form</legend>
        <div className={`${styles.submitBox} ${submitBoxClassName}`}>
          <input
            className={styles.input}
            onFocus={handleInputFocus}
            id={inputId}
            type="email"
            name="email"
            autoComplete="email"
            placeholder={newsletterPlaceholder}
            aria-label={newsletterPlaceholder}
            aria-describedby={
              state.error
                ? `${inputId}-error`
                : state.success
                ? 'newsletter-success'
                : null
            }
            size={15}
            required
          />
          <label className={styles.label} htmlFor={inputId}>
            {newsletterPlaceholder}
          </label>
          <button
            className={`${styles.button} ${buttonClassName}`}
            type="submit"
            onClick={handleFormSubmit}
            disabled={state.submitting}
          >
            {state.success ? (
              <CheckmarkIcon
                className={styles.checkmarkIcon}
                aria-hidden={true}
                title="submit"
              />
            ) : (
              t('sign_up')
            )}
          </button>
        </div>
        {state.error && (
          <NewsletterFormError for={state.error.formControl}>
            {state.error.message}
          </NewsletterFormError>
        )}
        {state.success && (
          <NewsletterFormSuccess>
            {newsletterSuccessMessage}
          </NewsletterFormSuccess>
        )}
      </fieldset>
      <ReCAPTCHABranding
        promotion={promotion}
      />
    </form>
  );
}

NewsletterForm.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  submitBoxClassName: PropTypes.string,
  promotion: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onSubmitSuccess: PropTypes.func,
};

export default NewsletterForm;
