import PropTypes from 'prop-types';
import { title } from './newsletter-title.module.scss';

function NewsletterTitle({ children }) {
  return <h2 className={title}>{children}</h2>;
}

NewsletterTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default NewsletterTitle;
