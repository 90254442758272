import PropTypes from 'prop-types';
import { container } from './newsletter-signup-container.module.scss';

const NewsletterSignupContainer = ({ className, children }) => {
  return <div className={`${className} ${container}`}>{children}</div>;
};

NewsletterSignupContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

export default NewsletterSignupContainer;
