import styles from './recaptcha-branding.module.scss';

function ReCAPTCHABranding(props) {
  return (
    <div className={`xs-text-6 ${styles.privacyPolicyBox}`}>
      This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" className={props.promotion && `${styles.culturePopup}`}>Privacy Policy
      </a> and <a href="https://policies.google.com/terms" className={props.promotion && `${styles.culturePopup}`}>Terms of Service
      </a> apply.
    </div>
  );
}

export default ReCAPTCHABranding;
